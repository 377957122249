import { Fragment, memo } from "react";
import { sendTagOnContactCall } from "app/utils/analytics";
import "./HelpDeskPhoneNumber.scss";
import { FormattedMessage } from "react-intl";
import Separator from "app/pages/.shared/Separator";
import PropTypes from "prop-types";
import PhoneNumberSVA from "app/pages/.shared/HelpDeskPhoneNumber/PhoneNumberSVA/PhoneNumberSVA";
import RelativeLink from "../RelativeLink";
import IconPhoneFull from "app/pages/.shared/static/icons/IconPhoneFull";
import ClickToCallCta from "app/pages/.shared/ClickToCall/ClickToCallCta.jsx";

const HelpDeskPhoneNumber = ({
	dialPhone,
	displayPhone = [],
	officeHours = [],
	displayInternationalPhone = [],
	internationalDialPhone,
	callPrice,
	useSVA,
	showFAQLink,
	svaVariant,
	showPicto,
	displayPhoneNumber = true,
}) => {
	let faqLinkContent;
	if (showFAQLink) {
		faqLinkContent = (
			<Fragment>
				<Separator design="middle" />
				<div className="help-desk-phone-number__email">
					<FormattedMessage id="technical.error.registration.email.label" />
					<RelativeLink
						variant="naked"
						rel="noopener noreferrer"
						target="_blank"
						to={{
							pathname: "/faq",
						}}
					>
						<FormattedMessage id="technical.error.registration.email.link.label" />
					</RelativeLink>
				</div>
			</Fragment>
		);
	}

	if ((!displayPhone?.length && !displayInternationalPhone?.length) || !displayPhoneNumber) {
		return (
			<div className="help-desk-phone-number">
				<ClickToCallCta officeHours={officeHours} />
				{faqLinkContent}
			</div>
		);
	}

	return (
		<div className="help-desk-phone-number">
			{useSVA && svaVariant ? (
				<PhoneNumberSVA
					dialPhone={dialPhone}
					displayPhone={displayPhone}
					callPrice={callPrice}
					svaVariant={svaVariant}
				/>
			) : (
				<div
					className="help-desk-phone-number__number"
					data-testid="help-desk-phone-number"
				>
					{showPicto && <IconPhoneFull height={20} width={20} />}
					{displayPhone?.length > 0 && (
						<a
							onClick={sendTagOnContactCall}
							className="help-desk-phone-number__link"
							href={`tel:${dialPhone}`}
						>
							{displayPhone.join(" ")}
						</a>
					)}
				</div>
			)}
			<div className="help-desk-phone-number__info" data-testid="help-desk-phone-info">
				{officeHours.map((officeHour, key) => {
					return <span key={key}>{officeHour}</span>;
				})}
				{(!useSVA || !svaVariant) && (
					<span className="help-desk-phone-number__price">{callPrice}</span>
				)}
			</div>

			{displayInternationalPhone && displayInternationalPhone.length > 0 && (
				<div
					className="help-desk-phone-number__international"
					data-testid="help-desk-phone-international-number"
				>
					<Separator design="middle" />

					<div className="help-desk-phone-number__intro">
						<FormattedMessage id="help.desk.abroad.intro" />
					</div>

					<div className="help-desk-phone-number__number">
						<a
							onClick={sendTagOnContactCall}
							className="help-desk-phone-number__link"
							href={`tel:${internationalDialPhone}`}
						>
							{displayInternationalPhone.join(" ")}
						</a>
					</div>

					<div className="help-desk-phone-number__info">
						<FormattedMessage id="help.desk.abroad.price.note" />
					</div>
				</div>
			)}
			{faqLinkContent}
		</div>
	);
};

HelpDeskPhoneNumber.propTypes = {
	dialPhone: PropTypes.string,
	showFAQLink: PropTypes.bool,
	displayPhone: PropTypes.arrayOf(PropTypes.string),
	internationalDialPhone: PropTypes.string,
	displayInternationalPhone: PropTypes.arrayOf(PropTypes.string),
	officeHours: PropTypes.arrayOf(PropTypes.string),
	callPrice: PropTypes.string,
	useSVA: PropTypes.bool,
	svaVariant: PropTypes.object,
	showPicto: PropTypes.bool,
	displayPhoneNumber: PropTypes.bool,
};

export default memo(HelpDeskPhoneNumber);
