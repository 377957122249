import PropTypes from "prop-types";
import classNames from "classnames";
import { htmlSanitize } from "app/utils/sanitize";
import "./List.scss";

const List = ({ items = [], enableHTMLFormatting = false, renderIcon, className }) => {
	const shouldRenderCustomBullet = typeof renderIcon === "function";

	const listItemClass = classNames("list__item", {
		"list__item--custom": shouldRenderCustomBullet,
	});

	const itemsArray = Array.isArray(items) ? items : [];
	return (
		<ul className={`list ${className}`}>
			{itemsArray.map((item, index) => {
				return enableHTMLFormatting ? (
					<li
						key={index}
						className="list__item"
						dangerouslySetInnerHTML={{ __html: htmlSanitize(item) }}
					/>
				) : (
					<li key={index} className={listItemClass}>
						{shouldRenderCustomBullet && (
							<div className="list__item__icon">{renderIcon()}</div>
						)}
						<div className="list__item__content">{item}</div>
					</li>
				);
			})}
		</ul>
	);
};

List.propTypes = {
	className: PropTypes.string,
	items: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.element])),
	renderIcon: PropTypes.func,
	enableHTMLFormatting: PropTypes.bool,
};

export default List;
